






















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Card } from '@/types/cms/components';
import { Product } from '@/types/neuhaus-website/cms/pages/products';

/**
 * CMSAdminComponentProductFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-product-information-list': () => import(
            './informations/CMSAdminComponentProductInformationList.vue'
        )
    }
})
export default class CMSAdminComponentProductFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageProduct data
    @Prop({type: Object as () => Card<Product>}) readonly editedProductCardItem: Card<Product> | undefined;

    // Prop that holds the id of the edited pageProduct
    @Prop(Number) readonly editedProductCardIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'Product').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageProduct
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveProduct() {
        if (this.editedProductCardItem) {
            this.resetFormValidation();

            this.$emit('productSaved', 
                this.dialogMode, 
                this.editedProductCardIndex,
                this.editedProductCardItem
            );
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
